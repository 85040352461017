import React from 'react'
import { useSelector } from 'react-redux'

import getPeopleTabNames from 'services/getPeopleTabNames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import NavbarLink from 'components/navbar/navbarLink'
import GlobalSearch from 'components/search/globalSearch'
import CompanyLogo from 'components/common/companyLogo'
import NotificationBell from 'components/notifications/notificationBell'
import NewPostButton from 'components/groups/posts/newPostButton'
import Banners from 'components/banners/banners'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useFirstAdminPath from 'components/common/hooks/useFirstAdminPath'
import AppLauncher from 'components/app_launcher/appLauncher'
import ProfileDropdown from 'components/navbar/profileDropdown'
import MobileNav from 'components/navbar/mobileNav'
import useWindowResize from 'components/common/hooks/useWindowResize'
import navbarSlice from 'redux/slices/navbarSlice'
import classNames from 'classnames'
import AskAiNavbarButton from 'components/ai/chat/ask_ai_modal/askAiNavbarButton'
import AskAiModal from 'components/ai/chat/ask_ai_modal/askAiModal'
import { AskAiProvider } from 'components/ai/chat/ask_ai_modal/askAiContext'

const I18N = i18nPath('views.navbar')

const TopNavbar = () => {
  const logo = useSelector(state => (state as any).logo)
  const isHidden = useSelector(navbarSlice.selectors.isNavbarHidden())
  const { isUpToMediumScreen: useMobileNav } = useWindowResize()

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const isAiPlatform = currentCompany?.type === 'ai_platform'

  const inPreboardingExperience = currentUser.inPreboardingExperience
  const showAppLauncher = currentCompany.settings.appLauncher?.enabled
  const isChatbotEnabled = currentCompany.settings.aiChatbot?.enabled && currentUser.aiChatbotEnabled

  const getNavbarColor = () => {
    const { color } = logo
    return color ? { backgroundColor: color } : {}
  }

  const firstAdminPath = useFirstAdminPath()

  const getNavLinks = () => {
    const { settings } = currentCompany
    const homeUrl = _.get(settings, 'feed.enabled') ? '/' : settings.home.baseUrl
    const replaceHomeWithAppName = _.get(settings, 'feed.replaceHomeWithAppname')
    const navLinks: any[] = []

    if (settings.feed.enabled) {
      navLinks.push(
        <NavbarLink key='home' exact to={homeUrl}>
          {replaceHomeWithAppName ? currentCompany.appName : I18N('home')}
        </NavbarLink>
      )
    }

    // for now we only add home nav link to users in preboarding experience
    if (inPreboardingExperience) {
      return navLinks
    }

    if (isAiPlatform) {
      navLinks.push(
        <NavbarLink key='dashboard' to='/dashboard'>
          {I18NCommon('dashboard')}
        </NavbarLink>
      )
    }

    if (settings.news.enabled) {
      navLinks.push(
        <NavbarLink key='news' to='/news'>
          {I18N('news')}
        </NavbarLink>
      )
    }

    if (settings.people.enabled) {
      navLinks.push(
        <NavbarLink key='people' to='/people'>
          {I18N('people')}
        </NavbarLink>
      )
    }

    if (settings.pages.enabled) {
      navLinks.push(
        <NavbarLink key='pages' to='/pages'>
          {currentCompany.pageDisplayName || I18N('pages')}
        </NavbarLink>
      )
    }

    if (settings.golinks.enabled) {
      navLinks.push(
        <NavbarLink key='golinks' to='/golinks'>
          {I18N('golinks')}
        </NavbarLink>
      )
    }

    if (settings.qna.enabled) {
      navLinks.push(
        <NavbarLink key='qna' to='/events'>
          {currentCompany.eventDisplayName || I18N('events')}
        </NavbarLink>
      )
    }

    // render an 'Admin' tab if user has any admin path available to them and
    // is not in restricted configuration preview mode
    if (firstAdminPath && !currentUser.restrictedAudienceConfigurationPreviewMode) {
      navLinks.push(
        <NavbarLink key='admin' to='/admin'>
          {I18N('admin')}
        </NavbarLink>
      )
    }

    return navLinks
  }

  const getSubnavLinks = () => {
    const { settings } = currentCompany


    const subnavLinkTabNames = getPeopleTabNames(settings)
    const baseRoute = 'people'

    return subnavLinkTabNames.map(value => (
      <NavbarLink key={value} to={`/${baseRoute}/${value}`}>
        {value === 'teams' ? currentCompany.pluralGroupDisplayName : I18N(value)}
      </NavbarLink>
    ))
  }

  const navLinks = getNavLinks()
  const subnavLinks = getSubnavLinks()

  return (
    <header
      // position and z-index necessary to show boxshadow everywhere,
      // and is not covered by anything underneath.
      className={classNames('TopNavbar position-relative zindex-fixed', { isHidden })}
    >
      <div className='Navbar-primary' style={getNavbarColor()}>
        {useMobileNav && (
          <div className='w-100'>
            <MobileNav
              navLinks={navLinks}
              peopleLinks={subnavLinks}
              inPreboardingExperience={inPreboardingExperience}
            />
          </div>
        )}
        <div className='container-fluid d-none d-md-block'>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <CompanyLogo className='Navbar-companyImage' />

            <div className='d-flex align-items-center'>
              <GlobalSearch />

              {!inPreboardingExperience && isChatbotEnabled && (
                <AskAiProvider>
                  <AskAiNavbarButton />
                  <AskAiModal />
                </AskAiProvider>
              )}
            </div>

            <div className='d-flex align-items-center gap-2'>
              {!inPreboardingExperience && (
                <>
                  {showAppLauncher && <AppLauncher />}

                  <NotificationBell />

                  <div className='d-none d-sm-block'>
                    <NewPostButton iconFill='var(--nav-text-active-color)' />
                  </div>
                </>
              )}

              <ProfileDropdown inPreboardingExperience={inPreboardingExperience} />
            </div>
          </div>
        </div>
      </div>


      {currentCompany.settings.banners.enabled && !inPreboardingExperience && (
        <Banners />
      )}
    </header>
  )
}

export default TopNavbar
