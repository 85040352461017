import { TicketingMessageType } from 'types/ticketing/message'
import { SimpleUserType, UserType } from 'types/user'

export enum TicketStatusEnum {
  New = 'new',
  InProgress = 'in_progress',
  Closed = 'closed',
}

export enum TicketOriginEnum {
  Web = 'web',
  Admin = 'admin',
}

export type TicketType = {
  createdAt: string
  id: string
  number: number
  status: TicketStatusEnum
  overdue: boolean
  dueDate: string | null
  description: string
  title: string
  user: SimpleUserType | UserType
  origin: TicketOriginEnum
  assignee?: SimpleUserType
  creator?: SimpleUserType
  updatedAt: string
}

export type TicketEntryType = {
  id: string
  entryable: TicketingMessageType
  entryableId: string
  entryableType: string
  createdAt: string
  updatedAt: string
}
