import React, { useState } from 'react'
import {
  Redirect, Route, Switch, useLocation
} from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import ReactGA from 'react-ga'

import getPeopleTabNames from 'services/getPeopleTabNames'
import useUpdateUserTimezone from 'components/common/hooks/useUpdateUserTimezone'

import GolinksExtensionBanner from 'components/golinksExtensionBanner'
import LanguageCheckBanner from 'components/languageCheckBanner'

import SearchResultsPage from 'pages/search/searchResultsPage'
import Navbar from 'components/navbar/navbar'
import ProfilePage from 'pages/people/profilePage'
import ArticlePage from 'components/articles/articlePage'
import BadgeListPage from 'pages/badges/badgeListPage'
import BadgePage from 'pages/badges/badgePage'
import createBadgePage from 'pages/badges/createBadgePage'
import EditPageTemplatePage from 'pages/templates/editPageTemplatePage'
import AwardedBadgePage from 'pages/badges/awardedBadgePage'
import NewsPage from 'pages/news/newsPage'
import GroupsListPage from 'pages/groups/groupsListPage'
import GroupPage from 'pages/groups/groupPage'
import ShoutoutsListPage from 'pages/people/shoutoutsListPage'
import ShoutoutPage from 'pages/people/shoutoutPage'
import CelebrationPage from 'pages/people/celebrationPage'
import GoLinksListPage from 'pages/golinks/goLinksListPage'
import OrgChartPage from 'pages/people/orgChartPage'
import EventsListPage from 'pages/qna/eventsListPage'
import EventPageContainer from 'pages/qna/eventPageContainer'
import QuestionPage from 'pages/qna/questionPage'
import NewEmployeesPage from 'pages/people/newEmployeesPage'
import WorkAnniversariesPage from 'pages/people/workAnniversariesPage'
import BirthdaysPage from 'pages/people/birthdaysPage'
import SettingsPage from 'pages/settings/settingsPage'
import SoftLaunchAccessFormPage from 'pages/softLaunchAccessFormPage'
import MyPostsListPage from 'pages/groups/posts/myPostsListPage'
import PostStatsPage from 'pages/groups/posts/postStatsPage'
import PostStatsEmailClicksPage from 'pages/groups/posts/postStatsEmailClicksPage'
import PostStatsEmailEngagementPage from 'pages/groups/posts/postStatsEmailEngagementPage'
import UserSkillPage from 'pages/userSkills/userSkillPage'
import MyAppsListPage from 'pages/app_launcher/myAppsListPage'
import RespondSurveyPage from 'pages/surveys/respondSurveyPage'
import PlatformAnalyticsPage from 'pages/analytics/platformAnalyticsPage'
import AiPlatformDashboardPage from 'pages/ai_platform/dashboardPage'
import AiPlatformKnowledgeIngestionPage from 'pages/ai_platform/knowledgeIngestionPage'

import CreateGoLinkPage from 'pages/golinks/createGoLinkPage'
import EditGoLinkPage from 'pages/golinks/editGoLinkPage'

import OnboardingBuddiesOptInPage from 'pages/journeys/onboarding_buddies/optInPage'
import OnboardingBuddiesOptOutPage from 'pages/journeys/onboarding_buddies/optOutPage'
import OnboardingBuddiesDoNotWantOnboardingBuddyPage from 'pages/journeys/onboarding_buddies/doNotWantOnboardingBuddyPage'
import SelectOnboardingBuddyPage from 'pages/journeys/onboarding_buddies/selectOnboardingBuddyPage'
import MyTasksPage from 'pages/journeys/my_tasks/myTasksPage'
import TaskDetailPage from 'pages/journeys/my_tasks/taskDetailPage'
import IntroductionDetailPage from 'pages/journeys/introductions/introductionDetailPage'

import SlackFeedbackWidget from 'components/common/slackFeedbackWidget'
import LegalNoticesModal from 'components/common/legalNoticesModal'

import ErrorBoundary from 'components/errorBoundaries'
import AdminAppRouter from 'components/admin/adminAppRouter'
import PreboardingRouter from 'components/preboarding/preboardingRouter'
import Error404 from 'components/errors/404'
import ToastMessage from 'components/common/toastMessage'
import { PathnameContextProvider } from 'components/contexts/pathnameContext'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useMobileAppGlobals from 'components/common/hooks/useMobileAppGlobals'
import PageAnalyticsPage from 'pages/analytics/pages/pageAnalyticsPage'
import StalePagesPage from 'pages/analytics/pages/stalePagesPage'
import InactiveOwnerPagesPage from 'pages/analytics/pages/inactiveOwnerPagesPage'
import PopularPagesPage from 'pages/analytics/pages/popularPagesPage'
import MarkPagesAsUpdatedPage from 'pages/pages/markPagesAsUpdatedPage'
import MostEngagingArticlesPage from 'pages/analytics/news/mostEngagingArticlesPage'
import MostEngagingAuthorsPage from 'pages/analytics/news/mostEngagingAuthorsPage'
import CultureActiveSignersPage from 'pages/analytics/culture/cultureActiveSignersPage'
import ShoutoutActivityPage from 'pages/analytics/culture/shoutoutActivityPage'
import SearchQueryResultsPage from 'pages/analytics/search/searchQueryResultsPage'
import SelectedResultTypesPage from 'pages/analytics/search/selectedResultTypesPage'
import TopSelectedResultsPage from 'pages/analytics/search/topSelectedResultsPage'
import RestrictedAudienceConfigurationPreviewModeBanner from 'components/restrictedAudienceConfigurationPreviewModeBanner'
import CommunicationPage from 'pages/journeys/communications/communicationPage'
import ArticleEditorPage from 'pages/admin/articles/articleEditorPage'
import PageNavAndRoutes, { PAGES_PATHS } from 'components/pages/pageNavAndRoutes'
import CustomHomePage from 'pages/home/customHomePage'
import FeedPage from 'pages/home/feedPage'
import CelebrationEditorPage from 'pages/people/celebration_editor_page/celebrationEditorPage'
import CelebrationOpenCardPage from 'pages/people/celebrationOpenCardPage'
import SlideShowPage from 'pages/celebrations/slideShowPage'
import CelebrationsListPage from 'pages/people/celebrations_list_page/celebrationsListPage'
import AnswersByTopicPage from 'pages/analytics/ai_answers/answersByTopicPage'
import FAQPage from 'pages/pages/faqPage'
import AiAnswersWizardModal from 'components/common/aiAnswersWizardModal'
import SourcePage from 'pages/external_sync/sourcePage'
import ScanningChannelsBottomBanner from 'components/common/scanningChannelsBottomBanner'
import TicketListPage from 'pages/ticketing/ticketListPage'
import LeftNavbar from 'components/navbar/leftNavbar'
import navbarHeight from 'components/navbar/utils/navbarHeight'
import TopNavbar from 'components/navbar/topNavbar'
import useWindowResize from 'components/common/hooks/useWindowResize'

const ProtectedRoute = ({ isAllowed, ...routeProps }) => {
  if (!isAllowed) return <Error404 />

  return <Route {...routeProps} />
}

const PeopleRoutes = (props) => {
  const { settings } = props
  const peopleTabNames = getPeopleTabNames(settings)

  return (
    <Switch>
      <Route exact path={['/people/orgchart', '/people/orgchart/:username']} component={OrgChartPage} />

      {settings.celebrations.newEmployees.enabled && <Route exact path='/people/new_employees' component={NewEmployeesPage} />}
      {settings.celebrations.workAnniversaries.enabled && (
        <Route exact path='/people/work_anniversaries' component={WorkAnniversariesPage} />
      )}
      {settings.celebrations.birthdays.enabled && <Route exact path='/people/birthdays' component={BirthdaysPage} />}

      {peopleTabNames.length > 0 && <Redirect exact from='/people' to={`/people/${peopleTabNames[0]}`} />}

      <Route exact path='/people/teams' component={GroupsListPage} />
      <Route exact path='/people/shoutouts' component={ShoutoutsListPage} />
      {settings.celebrations.enabled && (
        <Route exact path='/people/celebrations' component={CelebrationsListPage} />
      )}
      <Route exact path='/people/shoutouts/:shoutoutId' component={ShoutoutPage} />
      {settings.celebrations.manualCelebrations.enabled && (
        <Route exact path='/people/celebrations/new' component={CelebrationEditorPage} />
      )}
      <Redirect exact from='/people/feed_events/:celebrationId' to='/people/celebrations/:celebrationId' />
      <Route exact path='/people/celebrations/:celebrationId' component={CelebrationPage} />
      {settings.celebrations.manualCelebrations.enabled && (
        <Route exact path='/people/celebrations/:celebrationId/edit' component={CelebrationEditorPage} />
      )}
      <Route exact path='/people/teams/type/:groupTypeName' component={GroupsListPage} />
      <Route exact path='/people/teams/type/:groupTypeName/team/:groupId' component={GroupPage} />
      <Route exact path='/people/badges' component={BadgeListPage} />
      <Route exact path='/people/badges/new' component={createBadgePage} />
      <Route exact path='/people/badges/:badgeId' component={BadgePage} />
      <Route exact path='/people/badges/:badgeId/awarded_badges/:awardedBadgeId' component={AwardedBadgePage} />

      <Route component={Error404} />
    </Switch>
  )
}

const AppRouter = (props) => {
  const location = useLocation()
  const [navbarHasBoxShadow, setNavbarHasBoxShadow] = useState(true)
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const { isUpToMediumScreen: hideLeftNavbar } = useWindowResize()

  useMobileAppGlobals()
  useUpdateUserTimezone(location, currentUser, currentCompany)

  const companyId = _.get(currentCompany, 'id')
  const settings = _.get(currentCompany, 'settings')
  const slackFeedbackChannelName = _.get(currentCompany, 'slackFeedbackChannelName')
  const isLoggedOut = _.isEmpty(currentUser)
  const isAiPlatform = currentCompany?.type === 'ai_platform'
  const isDashboardPage = location.pathname === '/dashboard'

  if (isLoggedOut) {
    return <Redirect to='/login' />
  }

  ReactGA.ga('set', 'dimension1', companyId.toString())
  ReactGA.pageview(location.pathname + location.search)

  if (currentUser.inPreboardingExperience) {
    return <PreboardingRouter />
  }

  const renderSoftLaunchAccessForm = settings.softLaunchWhitelist.enabled && !currentUser.softLaunchAccessGrantedAt
  const useNewUi = settings.newUi.enabled

  if (renderSoftLaunchAccessForm) {
    return <SoftLaunchAccessFormPage />
  }

  const {
    permissions: {
      analyticsAdmin,
    },
  } = currentUser

  return (
    <div className='AppRouter'>
      <LegalNoticesModal />
      <GolinksExtensionBanner />
      <LanguageCheckBanner />
      <ToastMessage />
      <RestrictedAudienceConfigurationPreviewModeBanner />

      {isAiPlatform && !currentCompany.termsAndConditionsAcceptedById && (
        <AiAnswersWizardModal />
      )}

      {useNewUi ? (
        !currentUser.inMobileApp && <TopNavbar />
      ) : (
        !currentUser.inMobileApp && <Navbar hasBoxShadow={navbarHasBoxShadow} />
      )}


      <div className='d-flex position-relative' style={{ height: `calc(100vh - ${navbarHeight()}px)` }}>
        {useNewUi && !currentUser.inMobileApp && !hideLeftNavbar && <LeftNavbar />}

        <div className='AppContent flex-nowrap h-100 overflow-y-auto flex-grow-1'>
          <HelmetProvider>
            <Helmet>
              <title>{currentCompany?.appName}</title>
            </Helmet>
            <PathnameContextProvider>
              <ErrorBoundary {...props}>
                <Switch>
                  {(settings.home.baseUrl && settings.home.baseUrl !== '/') && (
                    <Redirect exact from='/' to={settings.home.baseUrl} />
                  )}
                  <Route exact path='/' component={CustomHomePage} />

                  <Route exact path='/dashboard' component={AiPlatformDashboardPage} />

                  <Route
                    exact
                    path='/knowledge_ingestions/:knowledgeIngestionId'
                    component={AiPlatformKnowledgeIngestionPage}
                  />

                  <Route exact path='/home_preview/:layoutCollectionId' component={CustomHomePage} />
                  <Route exact path='/feed' component={FeedPage} />

                  <Route exact path='/sample-error' component={() => ''.nope()} />

                  <Route exact path='/search' component={SearchResultsPage} />

                  <Route exact path='/profile/:username' component={ProfilePage} />

                  <Route
                    path='/people'
                    render={props => (
                      <PeopleRoutes {...props} settings={settings} setNavbarHasBoxShadow={setNavbarHasBoxShadow} />
                    )}
                  />

                  {settings.news.enabled && <Route exact path='/news/' component={NewsPage} />}
                  {settings.news.enabled && <Route exact path='/news/:articleTypeName' component={NewsPage} />}
                  {settings.news.enabled && <Route exact path='/news/:articleType/:articleId' component={ArticlePage} />}

                  {/* Preserve links from old badges, redirect to new badges (inside people tab) */}
                  <Redirect from='/badges/:badgeId/awarded_badges/:awardedBadgeId' to='/people/badges/:badgeId/awarded_badges/:awardedBadgeId' />
                  <Redirect from='/badges/:badgeId' to='/people/badges/:badgeId' />
                  <Redirect from='/badges' to='/people/badges' />

                  <Route exact path='/pages/template_categories/:templateCategoryId/templates/:pageTemplateId/edit' component={EditPageTemplatePage} />

                  <Route exact path={['/pages/:pageSlug/faqs/:faqId', '/workspaces/:workspaceId/:pageSlug/faqs/:faqId']} component={FAQPage} />
                  <Route path={PAGES_PATHS} component={PageNavAndRoutes} />

                  <Route exact path='/mark_pages_as_updated' component={MarkPagesAsUpdatedPage} />

                  <Route exact path='/golinks' component={GoLinksListPage} />
                  <Route exact path='/golinks/new' component={CreateGoLinkPage} />
                  <Route exact path='/golinks/:goLinkId' component={EditGoLinkPage} />

                  {/* New react router syntax composes routes with JSX if components are built with hooks */}
                  <Route exact path='/events'>
                    <EventsListPage />
                  </Route>
                  <Route exact path='/events/new'>
                    <EventsListPage showCreateFlow={true} />
                  </Route>
                  <Route exact path='/events/:eventId/questions/:questionId'>
                    <QuestionPage />
                  </Route>
                  {/* order matters for the event routes, EventPageContainer should be last */}
                  <Route path='/events/:eventId/'>
                    <EventPageContainer />
                  </Route>

                  <Route key='settings' path='/settings/:selectedTab?' component={SettingsPage} />

                  <Route exact key='group-post-editor' path='/posts/:articleId' component={ArticleEditorPage} />
                  <Route exact key='group-post-stats' path='/posts/:articleId/stats' component={PostStatsPage} />
                  <Route exact key='group-post-stats-email-engagement-page' path='/posts/:articleId/stats/email_engagement' component={PostStatsEmailEngagementPage} />
                  <Route exact key='group-post-stats-email-clicks-page' path='/posts/:articleId/stats/email_clicks' component={PostStatsEmailClicksPage} />
                  <Route exact key='group-my-posts-list' path='/my_posts' component={MyPostsListPage} />
                  <Redirect exact path='/posts' to='/my_posts' />

                  <Route exact path='/user-skills/:userSkillType/:userSkillId' component={UserSkillPage} />

                  <Route exact path='/onboarding_buddies' component={SelectOnboardingBuddyPage} />
                  <Route exact path='/onboarding_buddies/opt_in' component={OnboardingBuddiesOptInPage} />
                  <Route exact path='/onboarding_buddies/opt_out' component={OnboardingBuddiesOptOutPage} />
                  <Route exact path='/onboarding_buddies/do_not_want_onboarding_buddy' component={OnboardingBuddiesDoNotWantOnboardingBuddyPage} />

                  <Route exact path='/my_tasks' component={MyTasksPage} />
                  <Route exact path='/tasks/:taskId' component={TaskDetailPage} />
                  <Route exact path='/introductions/:introductionId' component={IntroductionDetailPage} />
                  <Route exact path='/communications/:communicationId' component={CommunicationPage} />

                  <Route exact path='/apps' component={MyAppsListPage} />

                  <Route exact path='/surveys/:formId' component={RespondSurveyPage} />
                  <Route exact path='/tickets' component={TicketListPage} />

                  <Route exact path='/celebrations/:celebrationId/open_card' component={CelebrationOpenCardPage} />
                  <Route exact path='/celebrations/:celebrationId/slide_show/:slideNumber?' component={SlideShowPage} />

                  <Route exact path='/external_sync/sources/:sourceId' component={SourcePage} />

                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/:selectedTab?' component={PlatformAnalyticsPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/pages_analytics/popular_pages' component={PopularPagesPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/pages_analytics/stale_pages' component={StalePagesPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/pages_analytics/inactive_owner_pages' component={InactiveOwnerPagesPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/news/most_engaging_articles' component={MostEngagingArticlesPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/news/most_engaging_authors' component={MostEngagingAuthorsPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/culture/active_signers' component={CultureActiveSignersPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/culture/shoutout_activity' component={ShoutoutActivityPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/search/query_results' component={SearchQueryResultsPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/search/selected_result_types' component={SelectedResultTypesPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/search/top_selected_results' component={TopSelectedResultsPage} />
                  <ProtectedRoute isAllowed={analyticsAdmin} exact path='/platform_analytics/ai_answers/topics/:topicId' component={AnswersByTopicPage} />
                  <Route exact path='/platform_analytics/pages/:pageId' component={PageAnalyticsPage} />

                  <Route path='/admin' component={AdminAppRouter} />

                  <Route component={Error404} />
                </Switch>
              </ErrorBoundary>
            </PathnameContextProvider>
          </HelmetProvider>
        </div>
      </div>


      {settings.slackFeedback.enabled && <SlackFeedbackWidget channelName={slackFeedbackChannelName} />}
      {isAiPlatform && !isDashboardPage && <ScanningChannelsBottomBanner />}
    </div>
  )
}

export default AppRouter
