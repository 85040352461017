import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from 'components/icons/closeIcon'

import bannerSlice from 'redux/slices/banners'
import classNames from 'classnames'

const Banners = ({ adminBanner = false }) => {
  const dispatch = useDispatch()
  const banners = useSelector(bannerSlice.selectors.getBanners())
  const [closedBannerIds, setClosedBannerIds] = useState(JSON.parse(localStorage.getItem('closedBannerIds')) || [])
  const activeBanners = adminBanner ? [adminBanner] : banners.filter(banner => !closedBannerIds.includes(banner.id))

  const handleDecline = bannerId => setClosedBannerIds([...closedBannerIds, bannerId])

  useEffect(() => {
    dispatch(bannerSlice.asyncActions.fetchAll())
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('closedBannerIds', JSON.stringify(closedBannerIds))
  }, [closedBannerIds])

  if (_.isEmpty(activeBanners)) {
    return null
  }

  return (
    <>
      {activeBanners.map(banner => (
        <Banner banner={banner} key={`banner-${banner.id}`} handleDecline={handleDecline} />
      ))}
    </>
  )
}

export const Banner = ({
  banner, className = '', handleDecline = () => {}, showDecline = true,
}) => (
  <div className={classNames('Banner-container d-flex align-items-center position-relative', className)} role='banner'>
    <div className='container'>
      <div className='row'>
        <div className='col d-flex justify-content-center'>
          {banner.link ? (
            <a href={banner.link} target='_blank' rel='noopener noreferrer'>
              <div className='Banner-text'>{banner.text}</div>
            </a>
          ) : (
            <div className='Banner-text'>{banner.text}</div>
          )}
        </div>
      </div>
    </div>
    <div className='Banner-close position-absolute d-flex' onClick={() => handleDecline(banner.id)}>
      {showDecline && <CloseIcon />}
    </div>
  </div>
)

export default Banners
